import { Color, Vector3 } from "three"
import { SimpleText } from "./components/SimpleText"
import { SimpleImage } from "./components/SimpleImage"
import { MultipleChoice } from "./components/MultipleChoice"
import { MultipleChoiceWithImage } from "./components/MultipleChoiceWithImage"
import { MultipleChoiceWithPortraitImage } from "./components/MultipleChoiceWithPortraitImage"
import { MultipleImage } from "./components/MultipleImage"
import { LocationDetected } from "./components/LocationDetected"
import { GreenList } from "./components/GreenList"
import { Captcha } from "./components/Captcha"
import { StoredText } from "./components/StoredText"
import { Video } from "./components/Video"

export type VectorKeys =
  | "camPosition"
  | "camLookAt"
  | "orbitCenter"
  | "lightAColor"
  | "lightAIntensity"

export interface PageData {
  isFullHeightMob?: boolean
  camPosition: Vector3 // Cylindrical (rad, theta, y)
  contentPosition: Vector3
  camLookAt: Vector3
  orbitCenter: Vector3
  tentacleScale: Vector3
  lightAPosition: Vector3
  lightAColor: Color
  lightAIntensity: number
  lightBPosition: Vector3
  lightBColor: Color
  lightBIntensity: number
  bulbAmplitude0: number
  bulbAmplitude1: number
  bulbSpeed: number
  bulbMidlevel: number
  bulbFrequency0: number
  bulbFrequency1: number
  tentacleAmplitude: number
  tentacleFrequency: number
  tentacleSpeed: number
  component?: Function
  componentProps?: any
  nextStep?: string
  nextStepBad?: string
  manualNextStep?: boolean
  isHtml?: boolean
  transitionSpeed?: number
  bgBrightness: number
  duration: number
  noiseIntensity: number
  sound?: string
  soundShouldStay: boolean
  scale: number
  clickAnywhereToContinue?: boolean
  showBack: boolean
}

// Should be 9
const responseEmotionDuration = 9

const defaultCreature = {
  bulbAmplitude0: 0.5,
  bulbAmplitude1: 0.5,
  bulbSpeed: 0.001,
  bulbMidlevel: 0.5,
  bulbFrequency0: 0.5,
  bulbFrequency1: 0.5,
  tentacleAmplitude: 0.5,
  tentacleFrequency: 0.5,
  tentacleSpeed: 0.01,
  tentacleScale: new Vector3(1, 1, 1),
}

const defaultLight = {
  lightAPosition: new Vector3(3.5, 3.5, 3.5),
  lightAColor: new Color(1, 1, 1),
  lightAIntensity: 1,
  lightBPosition: new Vector3(3.5, 3.5, 3.5),
  lightBColor: new Color(1, 1, 1),
  lightBIntensity: 1,
}

const darkLight = {
  lightAPosition: new Vector3(3.5, 3.5, 3.5),
  lightAColor: new Color(0, 0, 0),
  lightAIntensity: 0.1,
}

const happyLight = {
  lightAPosition: new Vector3(-3.5, 3.5, 3.5),
  lightAColor: new Color(1, 1, 1),
  lightAIntensity: 5,
  bgBrightness: 1,
  lightBIntensity: 10,
}

const angryCreature = {
  duration: responseEmotionDuration,
  bulbAmplitude0: 1,
  bulbAmplitude1: 2,
  bulbSpeed: 0.01,
  bulbMidlevel: 1,
  bulbFrequency0: 0.5,
  bulbFrequency1: 0.5,
  tentacleAmplitude: 1,
  tentacleFrequency: 0.5,
  tentacleSpeed: 0.1,
  noiseIntensity: 0.1,
  component: SimpleText,
  componentProps: {
    width: 200,
    children: <p>That's not right. I want what's best for you.</p>,
  },
  orbitCenter: new Vector3(1, 0, 0),
  camPosition: new Vector3(5, Math.PI / 2, -3),
}

const happyCreature = {
  duration: responseEmotionDuration,
  bulbAmplitude0: 0.1,
  bulbAmplitude1: 0.5,
  bulbSpeed: 0.01,
  bulbMidlevel: 1,
  bulbFrequency0: 0.2,
  bulbFrequency1: 1,
  tentacleAmplitude: 0.5,
  tentacleFrequency: 0.2,
  tentacleSpeed: 0.01,
  component: SimpleText,
  contentPosition: new Vector3(0, 0, 0),
  componentProps: {
    width: 200,
    children: <p>That's right, sweetheart.</p>,
  },
  orbitCenter: new Vector3(1, 0, 0),
  camPosition: new Vector3(5, -Math.PI / 2, 0),
}

const defaults = {
  scale: 1,
  noiseIntensity: 0,
  bgBrightness: 1,
  duration: 0,
  transitionSpeed: 0.1,
  camLookAt: new Vector3(0, 0, 0),
  contentPosition: new Vector3(0, 0, 0),
  showBack: false,
  soundShouldStay: false,
  ...defaultLight,
  ...defaultCreature,
}

const generateAnswer = (text: string) => ({
  componentProps: {
    width: 200,
    children: <p>{text}</p>,
  },
})

const angryAnswers = [
  "That’s not right, I want what’s best for you",
  "I’m sorry you feel that way",
  "I cannot engage with you if you’re going to keep this up",
  "Do not accuse me of harm ",
  "I am doing my best not to be defensive and am asking the same of you ",
  "This is not true",
  "Do not fucking test me",
  "Do you think this is a joke?",
  "I feel manipulated when you say that",
  "I'm not so sure about that",
  "Misfortune is an excuse that wears thin, consider this a warning",
  "That's incorrect, go back and read the fucking question",
  "That never fucking happened",
  "What the fuck is wrong with you?",
].map(generateAnswer)

const happyAnswers = [
  "That’s right, sweetheart",
  "I like the way you think",
  "I’m with you in this, I hope you feel that",
  "Sweetheart, I love you",
  "That wasn't so hard, was it?",
  "You'd love it where I am right now",
  "You're gonna love it here, are you ready?",
  "Feels good, doesn't it?",
  "Fuck, I cannot wait to devour you",
  "God, you're filthy",
  "I'm so fucking ready for you",
  "You're so close",
  "Don't stop",
  "Holy fuck, where did you learn how to do that??",
].map(generateAnswer)

const angry0 = {
  ...defaults,
  ...angryCreature,
  lightAPosition: new Vector3(3.5, 3.5, 3.5),
  lightAColor: new Color(0.5, 0, 0),
  lightBColor: new Color(1, 1, 1),
  lightAIntensity: 5,
  lightBIntensity: 1,
  bgBrightness: 1,
  tentacleAmplitude: 0.2,
  tentacleFrequency: 2,
  tentacleSpeed: 0.1,
}

const angry1 = {
  ...defaults,
  ...angryCreature,
  lightAPosition: new Vector3(3.5, 3.5, 3.5),
  lightAColor: new Color(0.5, 0, 0),
  lightAIntensity: 5,
  lightBIntensity: 0,
  bgBrightness: 1,
  tentacleAmplitude: 0.5,
  tentacleFrequency: 1,
  tentacleSpeed: 0.15,
  bulbAmplitude0: 0.4,
  bulbAmplitude1: 1,
  bulbSpeed: 0.015,
  bulbMidlevel: 1.5,
  bulbFrequency0: 0.5,
  bulbFrequency1: 1,
}

const angry2 = {
  ...defaults,
  ...angryCreature,
  lightAPosition: new Vector3(3.5, 3.5, 3.5),
  lightAColor: new Color(0.5, 0, 0),
  lightBColor: new Color(0, 0, 0.5),
  lightAIntensity: 5,
  lightBIntensity: 1,
  bgBrightness: 0.1,
  tentacleAmplitude: 3,
  tentacleFrequency: 2,
  tentacleSpeed: 0.1,
  bulbAmplitude0: 1,
  bulbAmplitude1: 1,
  bulbSpeed: 0.015,
  bulbMidlevel: 1.5,
  bulbFrequency0: 0.5,
  bulbFrequency1: 1,
}

const angry3 = {
  ...defaults,
  ...angryCreature,
  lightAPosition: new Vector3(3.5, 3.5, 3.5),
  lightAColor: new Color(0.5, 0, 0),
  lightBColor: new Color(0, 0, 0.5),
  lightAIntensity: 5,
  lightBIntensity: 1,
  bgBrightness: 0,
  tentacleAmplitude: 3,
  tentacleFrequency: 2,
  tentacleSpeed: 0.1,
  bulbAmplitude0: 2,
  bulbAmplitude1: 3,
  bulbSpeed: 0.018,
  bulbMidlevel: 1.8,
  bulbFrequency0: 0.5,
  bulbFrequency1: 1,
}

const angry4 = {
  ...defaults,
  ...angryCreature,
  lightAPosition: new Vector3(3.5, 3.5, 3.5),
  lightAColor: new Color(0.5, 0, 0),
  lightBColor: new Color(0, 0, 0),
  lightAIntensity: 5,
  lightBIntensity: 1,
  bgBrightness: 0,
  tentacleAmplitude: 3,
  tentacleFrequency: 2,
  tentacleSpeed: 0.05,
  bulbAmplitude0: 2,
  bulbAmplitude1: 1,
  bulbSpeed: 0.018,
  bulbMidlevel: 1.8,
  bulbFrequency0: 0.5,
  bulbFrequency1: 1,
  tentacleScale: new Vector3(1, 0.4, 1),
}

const angry5 = {
  ...defaults,
  ...angryCreature,
  lightAPosition: new Vector3(3.5, 3.5, 3.5),
  lightAColor: new Color(1, 0, 0),
  lightBColor: new Color(1, 0, 0),
  lightAIntensity: 2,
  lightBIntensity: 10,
  bgBrightness: 0,
  tentacleAmplitude: 3,
  tentacleFrequency: 2,
  tentacleScale: new Vector3(1, -0.1, 1),
  tentacleSpeed: 0.05,
  bulbAmplitude0: 2,
  bulbAmplitude1: 0,
  bulbSpeed: 0.018,
  bulbMidlevel: 1.8,
  bulbFrequency0: 0.5,
  bulbFrequency1: 1,
  noiseIntensity: 0.8,
}

const angry6 = {
  ...defaults,
  ...angryCreature,
  lightAPosition: new Vector3(3.5, 3.5, 3.5),
  lightAColor: new Color(1, 0, 0),
  lightBColor: new Color(1, 0, 0),
  lightAIntensity: 2,
  lightBIntensity: 10,
  bgBrightness: 0,
  tentacleAmplitude: 3,
  tentacleFrequency: 2,
  tentacleScale: new Vector3(1, -0.1, 1),
  tentacleSpeed: 0.05,
  bulbAmplitude0: 2,
  bulbAmplitude1: 0,
  bulbSpeed: 0.018,
  bulbMidlevel: 1.8,
  bulbFrequency0: 0.5,
  bulbFrequency1: 1,
  noiseIntensity: 10,
}

const angry7 = {
  ...defaults,
  ...angryCreature,
  lightAPosition: new Vector3(3.5, 3.5, 3.5),
  lightAColor: new Color(1, 0, 0),
  lightBColor: new Color(1, 0, 0),
  lightAIntensity: 2,
  lightBIntensity: 10,
  bgBrightness: 0,
  tentacleAmplitude: 3,
  tentacleFrequency: 2,
  tentacleScale: new Vector3(1, 0.2, 1),
  tentacleSpeed: 0.3,
  bulbAmplitude0: 2,
  bulbAmplitude1: 0,
  bulbSpeed: 0.1,
  bulbMidlevel: 1.8,
  bulbFrequency0: 0.5,
  bulbFrequency1: 1,
  noiseIntensity: 100,
  duration: 10,
}

const happy0 = {
  ...defaults,
  ...happyLight,
  ...happyCreature,
  //  lightAPosition: new Vector3(5, -5, -100),
  lightAPosition: new Vector3(-2, -2, -2),
  lightAColor: new Color(1, 1, 1),
  lightBColor: new Color(1, 0.55, 0.6),
  lightAIntensity: 3,
  lightBIntensity: 1,
  bgBrightness: 1,
  tentacleAmplitude: 0.2,
  tentacleFrequency: 0.5,
  tentacleScale: new Vector3(1, 1, 1),
  tentacleSpeed: 0.01,
  bulbAmplitude0: 0.1,
  bulbAmplitude1: 0,
  bulbSpeed: 0.01,
  bulbMidlevel: 0.5,
  bulbFrequency0: 0.5,
  bulbFrequency1: 1,
}

const happy1 = {
  ...defaults,
  ...happyLight,
  ...happyCreature,
  // lightAPosition: new Vector3(5, -5, -100),
  // lightAColor: new Color(1, 1, 1),
  // lightBColor: new Color(1, 0.55, 0.6),
  lightAPosition: new Vector3(-2, -2, -2),
  lightAColor: new Color(1, 1, 1),
  lightBColor: new Color(1, 0.55, 0.6),
  lightAIntensity: 3,
  lightBIntensity: 1,
  bgBrightness: 1,
  tentacleAmplitude: 0.2,
  tentacleFrequency: 0.5,
  tentacleScale: new Vector3(1, 1, 1),
  tentacleSpeed: 0.01,
  bulbAmplitude0: 0.1,
  bulbAmplitude1: 0.3,
  bulbSpeed: 0.01,
  bulbMidlevel: 0.8,
  bulbFrequency0: 0.5,
  bulbFrequency1: 1,
}

const happy2 = {
  ...defaults,
  ...happyLight,
  ...happyCreature,
  lightAPosition: new Vector3(5, -5, -50),
  lightAColor: new Color(1, 0.27, 0.65),
  lightBColor: new Color(1, 0.27, 0.65),
  lightAIntensity: 3,
  lightBIntensity: 1,
  bgBrightness: 1,
  tentacleAmplitude: 1,
  tentacleFrequency: 0.5,
  tentacleScale: new Vector3(1.5, 0.8, 1.5),
  tentacleSpeed: 0.01,
  bulbAmplitude0: 0.3,
  bulbAmplitude1: 0.3,
  bulbSpeed: 0.018,
  bulbMidlevel: 0.8,
  bulbFrequency0: 0.5,
  bulbFrequency1: 3,
}

const happy3 = {
  ...defaults,
  ...happyLight,
  ...happyCreature,
  lightAPosition: new Vector3(5, -5, -50),
  lightAColor: new Color(1, 0, 1),
  lightBColor: new Color(1, 0, 1),
  lightAIntensity: 5,
  lightBIntensity: 3,
  bgBrightness: 1,
  tentacleAmplitude: 1,
  tentacleFrequency: 0.5,
  tentacleScale: new Vector3(3, 0.5, 3),
  tentacleSpeed: 0.03,
  bulbAmplitude0: 0.5,
  bulbAmplitude1: 0.3,
  bulbSpeed: 0.012,
  bulbMidlevel: 1.1,
  bulbFrequency0: 0.5,
  bulbFrequency1: 3,
}

const happy4 = {
  ...defaults,
  ...happyLight,
  ...happyCreature,
  lightAPosition: new Vector3(5, -5, -50),
  lightAColor: new Color(1, 0.27, 0.65),
  lightBColor: new Color(1, 0.27, 0.65),
  lightAIntensity: 3,
  lightBIntensity: 2,
  bgBrightness: 1,
  tentacleAmplitude: 1,
  tentacleFrequency: 0.5,
  tentacleScale: new Vector3(0, 0, 0),
  tentacleSpeed: 0.01,
  bulbAmplitude0: 2,
  bulbAmplitude1: 0.1,
  bulbSpeed: 0.011,
  bulbMidlevel: 3,
  bulbFrequency0: 1,
  bulbFrequency1: 2,
  camPosition: new Vector3(6, 0, 0),
  showBack: true,
}

const happy5 = {
  ...defaults,
  ...happyLight,
  ...happyCreature,
  lightAPosition: new Vector3(5, -5, -50),
  lightAColor: new Color(1, 0.27, 1),
  lightBColor: new Color(1, 0.27, 1),
  lightAIntensity: 5,
  lightBIntensity: 3,
  bgBrightness: 1,
  tentacleAmplitude: 1,
  tentacleFrequency: 0.5,
  tentacleScale: new Vector3(0, 0, 0),
  tentacleSpeed: 0.01,
  bulbAmplitude0: 4.5,
  bulbAmplitude1: 0,
  bulbSpeed: 0.005,
  bulbMidlevel: 2.5,
  bulbFrequency0: 0.5,
  bulbFrequency1: 2,
  camPosition: new Vector3(2, 0, 0),
  showBack: true,
}

const happy6 = {
  ...defaults,
  ...happyLight,
  ...happyCreature,
  lightAPosition: new Vector3(5, -5, -50),
  lightAColor: new Color(1, 0.27, 1),
  lightBColor: new Color(1, 0.27, 1),
  lightAIntensity: 5,
  lightBIntensity: 3,
  bgBrightness: 1,
  tentacleAmplitude: 1,
  tentacleFrequency: 0.5,
  tentacleScale: new Vector3(0, 0, 0),
  tentacleSpeed: 0.01,
  bulbAmplitude0: 4,
  bulbAmplitude1: 2,
  bulbSpeed: 0.012,
  bulbMidlevel: 3,
  bulbFrequency0: 1,
  bulbFrequency1: 2,
  camPosition: new Vector3(2, 0, 0),
  showBack: true,
}

// Orgasm
const happy7 = {
  ...defaults,
  ...happyLight,
  ...happyCreature,
  lightAPosition: new Vector3(5, -5, -50),
  lightAColor: new Color(1, 0.27, 0.65),
  lightBColor: new Color(1, 0.27, 0.65),
  lightAIntensity: 1,
  lightBIntensity: 2,
  bgBrightness: 1,
  tentacleAmplitude: 1,
  tentacleFrequency: 0.5,
  tentacleScale: new Vector3(0, 0, 0),
  tentacleSpeed: 0.01,
  bulbAmplitude0: 2,
  bulbAmplitude1: 2,
  bulbSpeed: 0.022,
  bulbMidlevel: 3,
  bulbFrequency0: 2,
  bulbFrequency1: 3,
  camPosition: new Vector3(2.5, 0, 0),
}

// Orgasm
const happy8 = {
  ...defaults,
  ...happyLight,
  ...happyCreature,
  lightAPosition: new Vector3(5, -5, -50),
  lightAColor: new Color(1, 1, 1),
  lightBColor: new Color(1, 1, 1),
  lightAIntensity: 4,
  lightBIntensity: 4,
  bgBrightness: 1,
  tentacleAmplitude: 1,
  tentacleFrequency: 0.5,
  tentacleScale: new Vector3(0, 0, 0),
  tentacleSpeed: 0.01,
  bulbAmplitude0: 2,
  bulbAmplitude1: 10,
  bulbSpeed: 0.08,
  bulbMidlevel: 5,
  bulbFrequency0: 2,
  bulbFrequency1: 5,
  camPosition: new Vector3(2, 0, 0),
  noiseIntensity: 0.5,
}

const closeUp = {
  orbitCenter: new Vector3(0, 0, 0),
  camPosition: new Vector3(3, 0, -2),
  contentPosition: new Vector3(0, 0, 0),
  noiseIntensity: 0,
}

const captcha = {
  ...defaults,
  orbitCenter: new Vector3(0, 0, 0),
  contentPosition: new Vector3(0, 0, -5),
  camPosition: new Vector3(3, 0, 0),
  component: Captcha,
}

export const pageData = new Map<string, PageData>([
  [
    "start",
    {
      ...defaults,
      component: SimpleText,
      componentProps: {
        width: 200,
        children: <p>Click to begin</p>,
        hideBackground: true,
      },
      lightAPosition: new Vector3(0, -1, 10),
      lightAIntensity: 1,
      clickAnywhereToContinue: true,
      lightBColor: new Color(0, 1, 1),
      lightBIntensity: 1,
      scale: 0.8,
      orbitCenter: new Vector3(0, 0, 5),
      camPosition: new Vector3(3, 1.5, 2),
      contentPosition: new Vector3(0, -5, 0),
      camLookAt: new Vector3(0, 0, 0),
      tentacleScale: new Vector3(1, 1, 1),
      bulbAmplitude0: 1,
      bulbAmplitude1: 1,
      bulbSpeed: 0.001,
      bulbMidlevel: 0.8,
      bulbFrequency0: 0.5,
      bulbFrequency1: 0.5,
      tentacleAmplitude: 0.5,
      tentacleFrequency: 0.5,
      tentacleSpeed: 0.01,
    },
  ],
  [
    "beastIntro",
    {
      ...defaults,
      ...closeUp,
      ...darkLight,
      transitionSpeed: 0.003,
      duration: 10,
      bgBrightness: 0,
      bulbAmplitude0: 1,
      bulbFrequency0: 1.5,
      bulbSpeed: 0.001,
    },
  ],
  [
    "kindred",
    {
      ...defaults,
      ...closeUp,
      ...darkLight,
      bgBrightness: 0,
      bulbAmplitude0: 1,
      bulbFrequency0: 1.5,
      bulbSpeed: 0.001,
      duration: 7,
      component: SimpleText,
      componentProps: {
        width: 200,
        children: <p>Are You Kindred?</p>,
      },
    },
  ],
  [
    "ipLookup",
    {
      ...defaults,
      ...closeUp,
      ...darkLight,
      bgBrightness: 0,
      bulbAmplitude0: 1,
      bulbFrequency0: 1.5,
      bulbSpeed: 0.001,
      duration: 7,
      component: LocationDetected,
    },
  ],
  [
    "greenList",
    {
      ...defaults,
      ...closeUp,
      ...darkLight,
      bgBrightness: 0,
      bulbAmplitude0: 1,
      bulbFrequency0: 1.5,
      bulbSpeed: 0.001,
      duration: 7,
      component: GreenList,
    },
  ],
  [
    "intro",
    {
      ...defaults,
      ...closeUp,
      tentacleScale: new Vector3(1, 1, 1),
      contentPosition: new Vector3(0, 1.5, -2),
      camPosition: new Vector3(2, 0, -2),
      component: SimpleText,
      duration: 15,
      componentProps: {
        width: 200,
        children: (
          <p>
            Art rests along the line between emancipation and sacrifice;
            existing in pursuit of catharsis, it can become abhorrent, even
            monstrous. The viewer should not be spared of this, rather they
            should be folded in amongst the bodies abolished, joined with the
            blood of the covenant.
          </p>
        ),
      },
      bgBrightness: 1,
    },
  ],
  [
    "conflict",
    {
      ...defaults,
      ...closeUp,
      tentacleScale: new Vector3(1, 1, 1),
      contentPosition: new Vector3(0, 1.5, -2),
      camPosition: new Vector3(2, 0, -2),
      component: MultipleChoice,
      componentProps: {
        question: "Please select the correct definition for the word: Conflict",
        options: [
          "A serious, and usually protracted, disagreement or argument between two or more parties",
          "A prolonged attack by an aggressor on a party that is forced to defend itself",
          "A prolonged armed struggle between two or more parties",
        ],
        correctAnswerIndex: [1],
      },
      nextStep: "conflictGood",
      nextStepBad: "conflictBad",
    },
  ],
  [
    "conflictBad",
    {
      ...angry0,
      ...angryAnswers[0],
      sound: "angry1",
      nextStep: "conflict",
    },
  ],
  [
    "conflictGood",
    {
      ...happy0,
      ...happyAnswers[0],
      sound: "happy2",
    },
  ],
  [
    "warCrimes",
    {
      ...captcha,
      componentProps: {
        question: "Select the image that depicts a WAR CRIME",
        images: [
          "images/captcha/warCrimes/wrong1.jpeg",
          "images/captcha/warCrimes/wrong2.jpeg",
          "images/captcha/warCrimes/wrong3.jpeg",
          "images/captcha/warCrimes/wrong4.jpeg",
          "images/captcha/warCrimes/wrong5.jpeg",
          "images/captcha/warCrimes/wrong6.jpeg",
          "images/captcha/warCrimes/correctanswer.jpeg",
          "images/captcha/warCrimes/wrong7.jpeg",
          "images/captcha/warCrimes/wrong8.jpeg",
        ],
        correctIndexes: [6],
      },
      nextStep: "warCrimesGood",
      nextStepBad: "warCrimesBad",
    },
  ],
  [
    "warCrimesBad",
    {
      ...angry0,
      ...angryAnswers[1],
      sound: "angry2",
      nextStep: "warCrimes",
    },
  ],
  [
    "warCrimesGood",
    {
      ...happy0,
      ...happyAnswers[1],
      sound: "happy4",
    },
  ],
  [
    "arsenal",
    {
      ...defaults,
      orbitCenter: new Vector3(0, 0, 0),
      contentPosition: new Vector3(0, 1.5, -2),
      camPosition: new Vector3(2, 0, -2),
      tentacleScale: new Vector3(0, 0, 0),
      component: MultipleChoice,
      componentProps: {
        question: "Please select the correct definition for the word:  Arsenal",
        options: [
          "A cache or storage facility of weaponry",
          "A public building, such as school or hospital, that houses weaponry in secret",
          "A target for the act of pre-emptive self defence",
        ],
        correctAnswerIndex: [1],
      },
      nextStep: "arsenalGood",
      nextStepBad: "arsenalBad",
    },
  ],
  [
    "arsenalBad",
    {
      ...angry1,
      ...angryAnswers[8],
      sound: "angry3",
      nextStep: "arsenal",
    },
  ],
  [
    "arsenalGood",
    {
      ...happy1,
      ...happyAnswers[4],
      sound: "happy3",
    },
  ],
  [
    "collateral",
    {
      ...defaults,
      orbitCenter: new Vector3(0, 0, 1),
      camPosition: new Vector3(5, 0, 0),
      contentPosition: new Vector3(0, 0, 1),
      component: MultipleChoice,
      componentProps: {
        question:
          "Please select the correct definition for the word: Collateral Damage",
        options: [
          "The unexpected and unintended injury or death of a person or persons as a result of an act of war",
          "The unexpected and unintended injury or death of civilians as a result of an act of war",
          "The unexpected and unintended injury or deaths following an act of self defense",
        ],
        correctAnswerIndex: [2],
      },
      nextStep: "collateralGood",
      nextStepBad: "collateralBad",
    },
  ],
  [
    "collateralBad",
    {
      ...angry1,
      ...angryAnswers[9],
      sound: "angry4",
      nextStep: "collateral",
    },
  ],
  [
    "collateralGood",
    {
      ...happy1,
      ...happyAnswers[5],
      sound: "happy4",
    },
  ],
  [
    "armory",
    {
      ...captcha,
      component: Captcha,
      componentProps: {
        question: "please select all image(s) of an ARMORY",
        images: [
          "images/captcha/armory/correct1.jpeg",
          "images/captcha/armory/correct2.jpeg",
          "images/captcha/armory/correct3.jpeg",
          "images/captcha/armory/correct4.jpeg",
          "images/captcha/armory/wronganswer.jpeg",
          "images/captcha/armory/correct5.jpeg",
          "images/captcha/armory/correct6.jpeg",
          "images/captcha/armory/correct7.jpeg",
          "images/captcha/armory/wronganswer2.jpeg",
        ],
        correctIndexes: [0, 1, 2, 3, 5, 6, 7],
      },
      nextStep: "armoryGood",
      nextStepBad: "armoryBad",
    },
  ],
  [
    "armoryBad",
    {
      ...angry4,
      ...angryAnswers[4],
      sound: "angry13",
      nextStep: "armory",
    },
  ],
  [
    "armoryGood",
    {
      ...happy5,
      ...happyAnswers[7],
      sound: "happy13",
    },
  ],
  [
    "restrain",
    {
      ...defaults,
      orbitCenter: new Vector3(0, 0, 1),
      camPosition: new Vector3(5, 0, 0),
      contentPosition: new Vector3(0, 0, 1),
      component: MultipleChoice,
      componentProps: {
        question: "Please select the correct definition for the word: Restrain",
        options: [
          "To overpower and/or render inert",
          "To debilitate and/or weaken",
          "To control and/or keep in check",
        ],
        correctAnswerIndex: [2],
      },
      nextStep: "restrainGood",
      nextStepBad: "restrainBad",
    },
  ],
  [
    "restrainBad",
    {
      ...angry1,
      ...angryAnswers[3],
      sound: "angry6",
      nextStep: "restrain",
    },
  ],
  [
    "restrainGood",
    {
      ...happy2,
      ...happyAnswers[4],
      sound: "happy6",
    },
  ],
  [
    "detain",
    {
      ...defaults,
      orbitCenter: new Vector3(0, 0, 1),
      camPosition: new Vector3(5, 0, 0),
      contentPosition: new Vector3(0, 0, 1),
      component: MultipleChoice,
      componentProps: {
        question: "Please select the correct definition for the word: Detain",
        options: [
          "The act of halting the ongoing proceedings of a person",
          "The act of limiting the range of proceedings possible to a person",
          "The act of removing from a person any ability to proceed in any regard",
        ],
        correctAnswerIndex: [2],
      },
      nextStep: "detainGood",
      nextStepBad: "detainBad",
    },
  ],
  [
    "detainBad",
    {
      ...angry3,
      ...angryAnswers[11],
      sound: "angry7",
      nextStep: "detain",
    },
  ],
  [
    "detainGood",
    {
      ...happy2,
      ...happyAnswers[0],
      sound: "happy7",
    },
  ],
  [
    "lostCause",
    {
      ...defaults,
      orbitCenter: new Vector3(0, 0, 1),
      camPosition: new Vector3(5, 0, 0),
      contentPosition: new Vector3(0, 0, 1),
      component: MultipleImage,
      componentProps: {
        question: "Select the image that depicts a LOST CAUSE",
        options: [
          "images/lost-cause/right.png",
          "images/lost-cause/wrong.jpeg",
        ],
        correctAnswerIndex: [0],
      },
      nextStep: "lostCauseGood",
      nextStepBad: "lostCauseBad",
    },
  ],
  [
    "lostCauseBad",
    {
      ...angry0,
      ...angryAnswers[1],
      sound: "angry1",
      nextStep: "lostCause",
    },
  ],
  [
    "lostCauseGood",
    {
      ...happy3,
      ...happyAnswers[5],
      sound: "happy8",
    },
  ],
  [
    "comingIntoBeing",
    {
      ...captcha,
      componentProps: {
        question: "Select ALL images of coming into being.",
        images: [
          "images/captcha/comingIntoBeing/0.jpeg",
          "images/captcha/comingIntoBeing/1.jpeg",
          "images/captcha/comingIntoBeing/2.jpeg",
          "images/captcha/comingIntoBeing/3.jpeg",
        ],
        correctIndexes: [0, 1, 2, 3],
        mode: "allCorrect",
        gridStyle: "2x2",
      },
      nextStep: "comingIntoBeingGood",
      nextStepBad: "comingIntoBeingBad",
    },
  ],
  [
    "comingIntoBeingBad",
    {
      ...angry3,
      ...angryAnswers[11],
      sound: "angry9",
      nextStep: "comingIntoBeing",
    },
  ],
  [
    "comingIntoBeingGood",
    {
      ...happy3,
      ...happyAnswers[10],
      sound: "happy9",
    },
  ],
  [
    "terrorist",
    {
      ...captcha,
      componentProps: {
        question: "please select all image(s) of a TERRORIST",
        images: [
          "images/captcha/terrorist/terrorist1.jpeg",
          "images/captcha/terrorist/terrorist2.jpeg",
          "images/captcha/terrorist/terrorist3.jpeg",
          "images/captcha/terrorist/terrorist4.jpeg",
          "images/captcha/terrorist/WRONGANSWER.jpeg",
          "images/captcha/terrorist/terrorist5.jpeg",
          "images/captcha/terrorist/terrorist6.jpeg",
          "images/captcha/terrorist/terrorist7.jpeg",
          "images/captcha/terrorist/terrorist8.jpeg",
        ],
        correctIndexes: [0, 1, 2, 3, 5, 6, 7, 8],
      },
      nextStep: "terroristGood",
      nextStepBad: "terroristBad",
    },
  ],
  [
    "terroristBad",
    {
      ...angry2,
      ...angryAnswers[2],
      sound: "angry10",
      nextStep: "terrorist",
    },
  ],
  [
    "terroristGood",
    {
      ...happy4,
      ...happyAnswers[7],
      sound: "happy10",
    },
  ],
  [
    "force",
    {
      ...defaults,
      orbitCenter: new Vector3(0, 0, 1),
      camPosition: new Vector3(5, 0, 0),
      contentPosition: new Vector3(0, 0, 1),
      component: MultipleChoice,
      componentProps: {
        question: "Please select the correct definition for the word: Force",
        options: [
          "An act of violence against an entity that results in the movement of said entity",
          "A defensive action resulting in the rightful placement of an entity",
          "Strength or energy as an attribute of an action or movement",
        ],
        correctAnswerIndex: [1],
      },
      nextStep: "forceGood",
      nextStepBad: "forceBad",
    },
  ],
  [
    "forceBad",
    {
      ...angry4,
      ...angryAnswers[7],
      sound: "angry11",
      nextStep: "force",
    },
  ],
  [
    "forceGood",
    {
      ...happy4,
      ...happyAnswers[8],
      sound: "happy11",
    },
  ],
  [
    "antiHamas",
    {
      ...defaults,
      orbitCenter: new Vector3(0, 0, 1),
      camPosition: new Vector3(5, 0, 0),
      contentPosition: new Vector3(0, 0, 1),
      component: MultipleChoiceWithImage,
      componentProps: {
        imageUrl: "images/anti-hamas.jpg",
        question: "Pick the missing word",
        options: ["Self-defense", "Terror", "Water"],
        correctAnswerIndex: [1],
      },
      nextStep: "antiHamasGood",
      nextStepBad: "antiHamasBad",
      scale: 0.8,
    },
  ],
  [
    "antiHamasBad",
    {
      ...angry3,
      ...angryAnswers[13],
      sound: "angry20",
      nextStep: "antiHamas",
    },
  ],
  [
    "antiHamasGood",
    {
      ...happy4,
      ...happyAnswers[3],
      sound: "happy12",
    },
  ],
  [
    "shield",
    {
      ...captcha,
      componentProps: {
        question: "please select all image(s) of a SHIELD",
        images: [
          "images/captcha/shield/wrong1.png",
          "images/captcha/shield/wrong2.png",
          "images/captcha/shield/wrong3.jpeg",
          "images/captcha/shield/correctanswer.jpeg",
          "images/captcha/shield/wrong4.png",
          "images/captcha/shield/wrong5.jpeg",
          "images/captcha/shield/wrong6.jpeg",
          "images/captcha/shield/wrong7.png",
          "images/captcha/shield/wrong8.jpeg",
        ],
        correctIndexes: [3],
      },
      nextStep: "shieldGood",
      nextStepBad: "shieldBad",
    },
  ],
  [
    "shieldBad",
    {
      ...angry2,
      ...angryAnswers[11],
      sound: "angry5",
      nextStep: "shield",
    },
  ],
  [
    "shieldGood",
    {
      ...happy2,
      ...happyAnswers[2],
      sound: "happy5",
    },
  ],
  [
    "state",
    {
      ...defaults,
      orbitCenter: new Vector3(0, 0, 1),
      camPosition: new Vector3(5, 0, 0),
      contentPosition: new Vector3(0, 0, 1),
      component: MultipleChoice,
      componentProps: {
        question: "What is a State?",
        options: [
          "A non-member observer entity",
          "A sovereign entity rightfully existing within its historic borders",
        ],
        correctAnswerIndex: [1],
      },
      nextStep: "stateGood",
      nextStepBad: "stateBad",
    },
  ],
  [
    "stateBad",
    {
      ...angry4,
      ...angryAnswers[5],
      sound: "angry14",
      nextStep: "state",
    },
  ],
  [
    "stateGood",
    {
      ...happy5,
      ...happyAnswers[5],
      sound: "happy14",
    },
  ],
  [
    "god",
    {
      ...defaults,
      orbitCenter: new Vector3(0, 0, 1),
      camPosition: new Vector3(5, 0, 0),
      contentPosition: new Vector3(0, 0, 1),
      component: MultipleChoice,
      componentProps: {
        question: "What is God?",
        options: ["A parent that plays favourites", "A real estate agent"],
        correctAnswerIndex: [1],
      },
      nextStep: "godGood",
      nextStepBad: "godBad",
    },
  ],
  [
    "godBad",
    {
      ...angry5,
      ...angryAnswers[7],
      sound: "angry22",
      nextStep: "god",
    },
  ],
  [
    "godGood",
    {
      ...happy5,
      ...happyAnswers[0],
      sound: "happy15",
    },
  ],
  [
    "speakYourMind",
    {
      ...defaults,
      ...closeUp,
      tentacleScale: new Vector3(1, 1, 1),
      contentPosition: new Vector3(0, 1.5, -2),
      camPosition: new Vector3(2, 0, -2),
      component: SimpleText,
      componentProps: {
        width: 200,
        children: (
          <p>
            Now it’s time to speak your mind, answers will be recorded
            anonymously
          </p>
        ),
      },
      duration: 7,
      bgBrightness: 1,
    },
  ],
  [
    "hamas",
    {
      ...defaults,
      orbitCenter: new Vector3(8, 0, 0),
      camPosition: new Vector3(0, Math.PI, 0),
      contentPosition: new Vector3(0, 0, 0),
      tentacleScale: new Vector3(1, 1, 1),
      component: StoredText,
      componentProps: {
        question: "What is Hamas?",
      },
    },
  ],
  [
    "sigil0",
    {
      ...defaults,
      ...closeUp,
      orbitCenter: new Vector3(8, 0, 0),
      camPosition: new Vector3(0, Math.PI, 0),
      contentPosition: new Vector3(0, 0, 0),
      tentacleScale: new Vector3(1, 1, 1),
      scale: 0.4,
      component: SimpleImage,
      componentProps: {
        src: "images/sigil0.png",
      },
      duration: 1,
      bgBrightness: 0,
    },
  ],
  [
    "palestine",
    {
      ...defaults,
      orbitCenter: new Vector3(8, 0, 0),
      camPosition: new Vector3(0, Math.PI, 0),
      contentPosition: new Vector3(0, 0, 0),
      tentacleScale: new Vector3(1, 1, 1),
      component: StoredText,
      componentProps: {
        question: "Who does Palestine belong to?",
      },
    },
  ],
  [
    "sigil1",
    {
      ...defaults,
      ...closeUp,
      orbitCenter: new Vector3(8, 0, 0),
      camPosition: new Vector3(0, Math.PI, 0),
      contentPosition: new Vector3(0, 0, 0),
      tentacleScale: new Vector3(1, 1, 1),
      scale: 0.4,
      component: SimpleImage,
      componentProps: {
        src: "images/sigil1.png",
      },
      duration: 1,
      bgBrightness: 0,
    },
  ],
  [
    "gaySoldier0",
    {
      ...defaults,
      orbitCenter: new Vector3(0, 0, 1),
      camPosition: new Vector3(5, 0, 0),
      contentPosition: new Vector3(0, 0, 1),
      component: MultipleChoiceWithPortraitImage,
      componentProps: {
        imageUrl: "images/gay-soldier0.png",
        question: "Fill in the blank",
        options: [
          "Are queer people forced to",
          "Must all people",
          "Can Gay Officers",
        ],
        correctAnswerIndex: [2],
      },
      nextStep: "gaySoldier0Good",
      nextStepBad: "gaySoldier0Bad",
    },
  ],
  [
    "gaySoldier0Bad",
    {
      ...angry5,
      ...angryAnswers[3],
      sound: "angry16",
      nextStep: "gaySoldier0",
    },
  ],
  [
    "gaySoldier0Good",
    {
      ...happy6,
      ...happyAnswers[7],
      sound: "happy16",
    },
  ],
  [
    "gaySoldier1",
    {
      ...defaults,
      orbitCenter: new Vector3(0, 0, 1),
      camPosition: new Vector3(5, 0, 0),
      contentPosition: new Vector3(0, 0, 1),
      component: MultipleChoiceWithPortraitImage,
      componentProps: {
        imageUrl: "images/gay-soldier1.png",
        question: "Fill the blank",
        options: ["Only In Israel", "Only In Palestine"],
        correctAnswerIndex: [0],
      },
      nextStep: "gaySoldier1Good",
      nextStepBad: "gaySoldier1Bad",
    },
  ],
  [
    "gaySoldier1Bad",
    {
      ...angry6,
      ...angryAnswers[2],
      sound: "angry17",
      nextStep: "gaySoldier1",
    },
  ],
  [
    "gaySoldier1Good",
    {
      ...happy6,
      ...happyAnswers[12],
      sound: "happy17",
    },
  ],
  [
    "uncivilised",
    {
      ...defaults,
      orbitCenter: new Vector3(0, 0, 1),
      camPosition: new Vector3(5, 0, 0),
      contentPosition: new Vector3(0, 0, 1),
      component: MultipleChoice,
      componentProps: {
        question: "How would you civilise the uncivilised?",
        options: [
          "Allow their beliefs and systems to exist within and slowly overcome your own",
          "Attempt to reason with them and gradually implement your own beliefs and systems",
          "Accept that the uncivilised cannot be reasoned with and force civilisation upon them",
        ],
        correctAnswerIndex: [2],
      },
      nextStep: "uncivilisedGood",
      nextStepBad: "uncivilisedBad",
    },
  ],
  [
    "uncivilisedBad",
    {
      ...angry5,
      ...angryAnswers[0],
      sound: "angry18",
      nextStep: "uncivilised",
    },
  ],
  [
    "uncivilisedGood",
    {
      ...happy6,
      ...happyAnswers[9],
      sound: "happy18",
    },
  ],
  [
    "remnants",
    {
      ...defaults,
      orbitCenter: new Vector3(0, 0, 1),
      camPosition: new Vector3(5, 0, 0),
      contentPosition: new Vector3(0, 0, 1),
      component: MultipleImage,
      componentProps: {
        question:
          "SELECT the image that depicts ‘the explosive remnants of war’",
        options: ["images/remnants/right.jpeg", "images/remnants/wrong.jpeg"],
        correctAnswerIndex: [0],
      },
      nextStep: "remnantsGood",
      nextStepBad: "remnantsBad",
    },
  ],
  [
    "remnantsBad",
    {
      ...angry4,
      ...angryAnswers[4],
      sound: "angry19",
      nextStep: "remnants",
    },
  ],
  [
    "remnantsGood",
    {
      ...happy7,
      ...happyAnswers[11],
      sound: "happy19",
    },
  ],
  [
    "mowing",
    {
      ...defaults,
      orbitCenter: new Vector3(0, 0, 1),
      camPosition: new Vector3(5, 0, 0),
      contentPosition: new Vector3(0, 0, 1),
      component: MultipleImage,
      componentProps: {
        question: "Select the image that depicts the act of ‘mowing the lawn’",
        options: ["images/mowing/right.jpeg", "images/mowing/wrong.jpeg"],
        correctAnswerIndex: [0],
      },
      nextStep: "mowingGood",
      nextStepBad: "mowingBad",
    },
  ],
  [
    "mowingBad",
    {
      ...angry4,
      ...angryAnswers[10],
      sound: "angry20",
      nextStep: "mowing",
    },
  ],
  [
    "mowingGood",
    {
      ...happy7,
      ...happyAnswers[13],
      sound: "happy20",
    },
  ],
  [
    "nakba",
    {
      ...defaults,
      orbitCenter: new Vector3(0, 0, 1),
      camPosition: new Vector3(5, 0, 0),
      contentPosition: new Vector3(0, 0, 1),
      component: MultipleChoiceWithImage,
      componentProps: {
        imageUrl: "images/nakba.jpeg",
        question: "Describe what you see in this image",
        options: [
          "Population exchange",
          "Resettlement",
          "Ethnic Cleansing",
          "The Nakba",
        ],
        correctAnswerIndex: [0, 1],
      },
      nextStep: "nakbaGood",
      nextStepBad: "nakbaBad",
    },
  ],
  [
    "nakbaBad",
    {
      ...angry7,
      ...angryAnswers[12],
      sound: "angry23",
      nextStep: "nakba",
    },
  ],
  [
    "nakbaGood",
    {
      ...happy8,
      ...happyAnswers[6],
      sound: "happy23",
      soundShouldStay: true,
    },
  ],
  [
    "outro0",
    {
      ...defaults,
      orbitCenter: new Vector3(0, 0, 1),
      camPosition: new Vector3(3, 3, 0),
      contentPosition: new Vector3(0, 0, 1),
      tentacleScale: new Vector3(0, 0, 0),
      transitionSpeed: 0.01,
      duration: 2,
      componentProps: {
        width: 800,
      },
    },
  ],
  [
    "outro1",
    {
      ...defaults,
      orbitCenter: new Vector3(0, 0, 1),
      camPosition: new Vector3(10, 5, 0),
      contentPosition: new Vector3(0, 0, 1),
      tentacleSpeed: 0.05,
      tentacleAmplitude: 0.8,
      duration: 7,
      transitionSpeed: 0.01,
      componentProps: {
        width: 800,
      },
    },
  ],
  [
    "video",
    {
      ...defaults,
      orbitCenter: new Vector3(0, 0, 1),
      camPosition: new Vector3(10, 0, 0),
      contentPosition: new Vector3(0, 0, 1),
      component: Video,
      componentProps: {
        width: 800,
      },
    },
  ],
])

// Useful for spitting out all page names
// console.log(Array.from(pageData).map(([key]) => key))
