import "@react-three/fiber"
import { useEffect, useRef } from "react"
import { Group } from "three"
import { Html } from "@react-three/drei"

export function ContentContainer(props: any) {
  const objRef = useRef<Group>(null)

  useEffect(() => {
    if (objRef && objRef.current) {
      objRef.current.lookAt(props.lookAtPosition)
    }
  }, [props.lookAtPosition])

  return (
    <group {...props} ref={objRef}>
      <Html pointerEvents={props.isVisible ? "auto" : "none"} transform>
        <div
          style={{
            // transition: "0.5s",
            opacity: props.isVisible ? 1 : 0.0001,
          }}
        >
          {props.children}
        </div>
      </Html>
    </group>
  )
}
