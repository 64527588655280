type callbackFunc0 = (a: string) => void
type callbackFunc1 = (a: { ip_address: string; country: string }) => void
function httpGetAsync(url: string, callback: callbackFunc0) {
  var xmlHttp = new XMLHttpRequest()
  xmlHttp.onreadystatechange = function () {
    if (xmlHttp.readyState === 4 && xmlHttp.status === 200)
      callback(xmlHttp.responseText)
  }
  xmlHttp.open("GET", url, true) // true for asynchronous
  xmlHttp.send(null)
}

const url =
  "https://ipgeolocation.abstractapi.com/v1/?api_key=3ee59cef441f40eda998e5ba4555f842"

export function getGeoData(callback: callbackFunc1) {
  httpGetAsync(url, (obj) => {
    callback(JSON.parse(obj))
  })
}
