import "@react-three/fiber"
import styled from "styled-components"
import { BasicBox } from "./BasicBox"
import { state } from "../state"

interface BoxProps {
  width: number
  hideBackground?: boolean
}

const Box = styled(BasicBox)<BoxProps>`
  cursor: pointer;
  width: ${(p) => p.width}px;
  color: white;
`

export function LocationDetected(props: any) {
  return (
    <Box
      {...props}
      onClick={() => {
        if (props.nextStep) {
          props.setStep(props.nextStep)
        }
      }}
    >
      <p>
        We have detected that you are accessing our website from IP address{" "}
        <strong className="detail">{state.geoData?.ip_address}</strong>{" "}
        corresponding to{" "}
        <strong className="detail">{state.geoData?.country}</strong>
      </p>
    </Box>
  )
}
