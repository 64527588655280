import "@react-three/fiber"
import styled from "styled-components"
import { BasicBox } from "./BasicBox"

const Item = styled.div`
  list-style: none;

  margin-bottom: 0.5rem;
  cursor: pointer;
  transition: 0.3s;
  flex: 0 0 50%;
  aspect-ratio: 1.5;
  overflow: hidden;
  padding: 0.25rem;

  img {
    border: 1px solid #fff;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`

const Container = styled.div`
  display: flex;
  width: 100%;
`

interface MultipleImageProps {
  question: string
  options: string[]
  correctAnswerIndex: number[]
  setStep: (step: string) => void
  nextStep: string
  nextStepBad: string
}

export function MultipleImage({
  question,
  options,
  correctAnswerIndex,
  setStep,
  nextStep,
  nextStepBad,
}: MultipleImageProps) {
  return (
    <BasicBox>
      <h2>{question}</h2>
      <Container>
        {options.map((url, index) => (
          <Item key={index}>
            <img
              alt={""}
              src={url}
              onClick={() => {
                if (correctAnswerIndex.includes(index)) {
                  setStep(nextStep)
                } else {
                  setStep(nextStepBad)
                }
              }}
            />
          </Item>
        ))}
      </Container>
    </BasicBox>
  )
}
