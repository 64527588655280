import "@react-three/fiber"
import { useFrame } from "@react-three/fiber"
import { useRef } from "react"
import { BackSide, MeshBasicMaterial } from "three"
import { usePageDataVector } from "../utils/usePageDataVector"

export function SkyBox() {
  const material = useRef<MeshBasicMaterial>()
  const brightness = usePageDataVector("bgBrightness", 1)

  useFrame(() => {
    if (brightness.current && material.current) {
      material.current.opacity = 1 - brightness.current
    }
  })

  return (
    <mesh>
      <meshBasicMaterial
        ref={material}
        side={BackSide}
        color={0x000000}
        opacity={0.9}
        transparent={true}
      />
      <sphereBufferGeometry args={[1000, 32, 32]} />
    </mesh>
  )
}
