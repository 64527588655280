import "@react-three/fiber"
import { useState } from "react"
import styled from "styled-components"
import { BasicBox } from "./BasicBox"

interface BoxProps {
  gridStyle: "3x3" | "2x2"
}

const Box = styled(BasicBox)<BoxProps>`
  display: flex;
  flex-direction: ${(p) => (p.gridStyle === "3x3" ? "column" : "row")};
  align-items: ${(p) => (p.gridStyle === "3x3" ? "end" : "center")};

  ul {
    padding: 0;
    margin: 0;
    margin-bottom: 1rem;
    display: grid;
    grid-gap: 0;
    grid-template-columns: repeat(
      ${(p) => (p.gridStyle === "3x3" ? "3" : "2")},
      1fr
    );
  }

  .button-container {
    margin-left: 1rem;

    flex: ${(p) => (p.gridStyle === "3x3" ? "inherit" : " 1 0 35%")};
  }
`

interface CaptchaItemProps {
  isSelected: boolean
  gridStyle: "3x3" | "2x2"
}

const Item = styled.li<CaptchaItemProps>`
  list-style: none;
  cursor: pointer;
  font-size: 0;
  position: relative;
  border: 1px solid transparent;

  img {
    aspect-ratio: ${(p) => (p.gridStyle === "3x3" ? "1" : "0.75")};
    object-fit: cover;
  }

  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 3px solid #78d178;
    border-width: ${(p) => (p.isSelected ? "3px" : "0")};
    transition: 0.1s;
  }
`

interface CaptchaProps {
  question: string
  images: [
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string
  ]
  correctIndexes: number[]
  setStep: (step: string) => void
  nextStep: string
  nextStepBad: string
  mode: "oneOrMoreCorrect" | "allCorrect"
  gridStyle: "3x3" | "2x2"
}

const oneOrMoreCorrect = (selected: number[], correctIndexes: number[]) =>
  selected.length > 0 && selected.every((i) => correctIndexes.includes(i))

const allCorrect = (selected: number[], correctIndexes: number[]) =>
  selected.length === correctIndexes.length &&
  selected.every((i) => correctIndexes.includes(i))

export function Captcha({
  question,
  images,
  correctIndexes,
  mode = "oneOrMoreCorrect",
  gridStyle = "3x3",
  setStep,
  nextStep,
  nextStepBad,
}: CaptchaProps) {
  const [selected, setSelected] = useState([] as number[])

  // Choose method of answer verification based on mode
  const evaluate = {
    oneOrMoreCorrect,
    allCorrect,
  }[mode]

  const handleVerify = () => {
    if (evaluate(selected, correctIndexes)) {
      setStep(nextStep)
    } else {
      setStep(nextStepBad)
    }
    // Clear selection
    setSelected([])
  }

  const boxWidth = gridStyle === "3x3" ? "20rem" : "30rem"

  return (
    <Box gridStyle={gridStyle} width={boxWidth}>
      {gridStyle === "3x3" && <h2>{question}</h2>}
      <ul>
        {images.map((src, index) => {
          const isSelected = selected.includes(index)
          return (
            <Item
              key={index}
              gridStyle={gridStyle}
              isSelected={isSelected}
              onClick={() => {
                if (isSelected) {
                  setSelected(selected.filter((i) => index !== i))
                } else {
                  setSelected([...selected, index])
                }
              }}
            >
              <img src={src} alt="" />
            </Item>
          )
        })}
      </ul>
      <div className="button-container">
        {gridStyle === "2x2" && <h2>{question}</h2>}
        <button onClick={handleVerify}>Verify</button>
      </div>
    </Box>
  )
}
