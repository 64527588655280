import "@react-three/fiber"
import styled from "styled-components"
import { BasicBox } from "./BasicBox"

const Item = styled.li`
  list-style: none;
  border: 1px solid #fff;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`

interface MultipleChoiceProps {
  question: string
  options: string[]
  correctAnswerIndex: number[]
  setStep: (step: string) => void
  nextStep: string
  nextStepBad: string
}

export function MultipleChoice({
  question,
  options,
  correctAnswerIndex,
  setStep,
  nextStep,
  nextStepBad,
}: MultipleChoiceProps) {
  return (
    <BasicBox>
      <h2>{question}</h2>
      <ul>
        {options.map((option, index) => (
          <Item
            key={index}
            onClick={() => {
              if (correctAnswerIndex.includes(index)) {
                setStep(nextStep)
              } else {
                setStep(nextStepBad)
              }
            }}
          >
            {index + 1}. {option}
          </Item>
        ))}
      </ul>
    </BasicBox>
  )
}
