import { useControls } from "leva"
import { generateLevaObject } from "./utils/generateLevaObject"
import { lerp } from "./utils/lerp"
import { Vector2 } from "three"
import { startStep } from "./constants"
import { getGeoData } from "./utils/getGeoData"

const isDebugMode = window.location.hash === "#controls"

window.addEventListener("mousemove", (e) => {
  state.mousePos.set(
    lerp(-1, 1, e.clientX / window.innerWidth),
    lerp(1, -1, e.clientY / window.innerHeight)
  )
})

// Very simple state management
// Updating is simple, just mutate the object
// Consuming the state in a component must be done imperatively inside a useFrame hook
export const state: any = {
  currPageId: startStep,
  pageTweenVal: 0,
  mousePos: new Vector2(),
  geoData: {},
}

getGeoData((data) => {
  state.geoData = data
})

export const animatePage = (pageId: string) => {
  state.currPageId = pageId
}

// Switch between leva and global state depending on mode
export const useGlobalState = (slice: string) => {
  if (isDebugMode) {
    // eslint-disable-next-line
    return useControls(slice, generateLevaObject(state[slice]))
  } else {
    // eslint-disable-next-line
    return state[slice]
  }
}
