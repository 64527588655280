import { Bulb } from "./Bulb"
import { Tentacles } from "./Tentacles"

export function Creature() {
  return (
    <group>
      <Bulb />
      <Tentacles />
    </group>
  )
}
