import { useEffect, useRef } from "react"
import { useFrame, useThree } from "@react-three/fiber"
import { PerspectiveCamera, Vector3 } from "three"
import { breakpoints } from "../constants"
import { state } from "../state"
import { usePageDataVector } from "../utils/usePageDataVector"

const mouseAmp = 0.5
const medBreak = breakpoints.medium

export function Camera() {
  const camera = useRef<PerspectiveCamera>(new PerspectiveCamera())

  // Fair play, hooks are pretty awesome!
  const cylindricalPos = usePageDataVector("camPosition", new Vector3())
  const orbitOffset = usePageDataVector("orbitCenter", new Vector3())
  const lookAt = usePageDataVector("camLookAt", new Vector3())

  const mouseOffset = useRef(new Vector3())
  const mouseOffsetTarget = useRef(new Vector3())

  const { size, set } = useThree()

  useEffect(() => {
    set({ camera: camera.current })
  }, [set])

  useFrame(() => {
    const cam = camera.current

    cam.aspect = size.width / size.height

    cam.near = 0.0001

    // Use horizontal fov instead of vertical
    const hfov = 105
    cam.fov =
      (Math.atan(Math.tan((hfov * Math.PI) / 360) / cam.aspect) * 360) / Math.PI

    cam.position.setFromCylindricalCoords(
      cylindricalPos.current.x,
      cylindricalPos.current.y,
      cylindricalPos.current.z
    )

    if (size.width >= medBreak) {
      // Damped orbit based on mouse pos
      mouseOffsetTarget.current.set(
        state.mousePos.x * mouseAmp,
        state.mousePos.y * mouseAmp,
        0
      )

      mouseOffset.current.lerp(mouseOffsetTarget.current, 0.1)

      cam.position.sub(mouseOffset.current)
    }

    cam.position.add(orbitOffset.current)

    cam.lookAt(lookAt.current)

    cam.updateProjectionMatrix()
  })

  return <perspectiveCamera ref={camera} />
}
