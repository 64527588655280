export type SaveItem = {
  question: string
  answer: string
}

export const saveItem = (question: string, answer: string) => {
  const raw = localStorage.getItem("answers")
  const storedData = raw ? JSON.parse(raw) : []
  storedData.push({
    question,
    answer,
  })

  localStorage.setItem("answers", JSON.stringify(storedData))
}
