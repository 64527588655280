import { useFrame } from "@react-three/fiber"
import {
  EffectComposer,
  Bloom,
  Noise,
  Vignette,
  Glitch,
  HueSaturation,
} from "@react-three/postprocessing"
import { useRef } from "react"
import { Vector2 } from "three"
import { usePageDataVector } from "../utils/usePageDataVector"

const delay = new Vector2(0, 0)

export function Effects() {
  const noise = usePageDataVector("noiseIntensity", 0)
  const noiseRef = useRef<any>()
  const glitchRef = useRef<any>()

  useFrame(() => {
    if (noiseRef.current) {
      noiseRef.current.blendMode.opacity.value = noise.current
    }

    if (glitchRef.current) {
      glitchRef.current.mode = noise.current > 5 ? 1 : 0
    }
  })

  return (
    <EffectComposer multisampling={0}>
      <Bloom luminanceThreshold={0.01} luminanceSmoothing={1} height={300} />
      <Noise ref={noiseRef} />
      <Vignette eskil={false} offset={0.1} darkness={0.6} />
      <Glitch
        ref={glitchRef}
        delay={delay} // min and max glitch delay
        ratio={0.85} // Threshold for strong glitches, 0 - no weak glitches, 1 - no strong glitches.
      />
    </EffectComposer>
  )
}
