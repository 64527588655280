import "@react-three/fiber"
import styled from "styled-components"
import { BasicBox } from "./BasicBox"

const Image = styled.img`
  max-width: 70%;
  margin-bottom: 0.5rem;
`

const List = styled.ul`
  display: flex;
`

const Item = styled.li`
  list-style: none;
  border: 1px solid #fff;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  transition: 0.3s;
  flex: 1;
  margin: 0 0.25rem;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`

interface MultipleChoiceWithImageProps {
  question: string
  options: string[]
  imageUrl: string
  correctAnswerIndex: number[]
  setStep: (step: string) => void
  nextStep: string
  nextStepBad: string
}

export function MultipleChoiceWithImage({
  imageUrl,
  question,
  options,
  correctAnswerIndex,
  setStep,
  nextStep,
  nextStepBad,
}: MultipleChoiceWithImageProps) {
  return (
    <BasicBox width="25rem">
      <Image src={imageUrl} alt="" />
      <h3>{question}</h3>
      <List>
        {options.map((option, index) => (
          <Item
            key={index}
            onClick={() => {
              if (correctAnswerIndex.includes(index)) {
                setStep(nextStep)
              } else {
                setStep(nextStepBad)
              }
            }}
          >
            {index + 1}.
            <br />
            {option}
          </Item>
        ))}
      </List>
    </BasicBox>
  )
}
