import "@react-three/fiber"
import styled from "styled-components"
import { BasicBox } from "./BasicBox"

interface BoxProps {
  width: number
  hideBackground?: boolean
}

const Box = styled(BasicBox)<BoxProps>`
  cursor: pointer;
  width: ${(p) => p.width}px;
  color: white;
`

export function SimpleText(props: any) {
  return (
    <Box
      {...props}
      onClick={() => {
        if (props.nextStep) {
          props.setStep(props.nextStep)
        }
      }}
    >
      {props.children}
    </Box>
  )
}
