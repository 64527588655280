/* eslint-disable react/jsx-pascal-case */

import { useGLTF } from "@react-three/drei"
import ComponentMaterial from "component-material"
import { MeshStandardMaterial, ShaderChunk, Vector2, Vector3 } from "three"
import { useRef } from "react"
import { useFrame } from "@react-three/fiber"
import displacebody from "../glsl/displacebody.glsl.js"
import verttop from "../glsl/verttop.glsl.js"
import { pageData } from "../page-data"
import { state } from "../state"
import { usePageDataVector } from "../utils/usePageDataVector"

const matName = "tentacle"

export function Tentacles() {
  const { nodes, materials } = useGLTF("gltf/tentacles.glb")

  const oldMat = materials[matName]

  const { color, metalness, roughness } = oldMat

  const material = useRef()
  const mesh = useRef()
  const time = useRef(0)

  const amplitude = usePageDataVector("tentacleAmplitude", 0.5)
  const frequency = usePageDataVector("tentacleFrequency", 0.5)
  const speed = usePageDataVector("tentacleSpeed", 0.5)
  const scale = usePageDataVector("tentacleScale", new Vector3(1, 1, 1))

  useFrame(({ clock }) => {
    time.current += speed.current
    material.current.time = time.current
    material.current.amplitude = amplitude.current
    material.current.frequency = frequency.current
    mesh.current.scale.lerp(scale.current, 0.9)
  })

  return (
    <mesh geometry={nodes.Cylinder.geometry} ref={mesh}>
      <ComponentMaterial
        from={MeshStandardMaterial}
        ref={material}
        color={color}
        metalness={metalness}
        roughness={roughness}
        uniforms={{
          time: { value: 0, type: "float" },
          frequency: { value: 0.5, type: "float" },
          amplitude: { value: 0.5, type: "float" },
        }}
      >
        <ComponentMaterial.Vert.Head>{
          /* glsl */ `
  ${verttop}

  vec3 displace(vec3 point) {
    float amp = amplitude * (point.y * .5) * 1.;
    float t = time + point.x * 0.1 + point.y * 0.1;
    float fq = frequency * 3.;
    point.x += sin(point.y * fq + t) * amp;
    point.z += cos(point.y * fq + t) * amp;
    return point;
  }
    `
        }</ComponentMaterial.Vert.Head>
        <ComponentMaterial.Vert.uv_vertex children={displacebody} />
        <ComponentMaterial.Vert.defaultnormal_vertex
          replaceChunk
          children={ShaderChunk.defaultnormal_vertex.replace(
            "vec3 transformedNormal = objectNormal;",
            `vec3 transformedNormal = displacedNormal;`
          )}
        />
        <ComponentMaterial.Vert.displacementmap_vertex
          replaceChunk
          children="transformed = displacedPosition;"
        />
      </ComponentMaterial>
    </mesh>
  )
}
