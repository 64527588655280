import styled from "styled-components"

interface Props {
  width?: string
  hideBackground?: boolean
}

export const BasicBox = styled.div<Props>`
  width: ${(p) => p.width || "20rem"};
  padding: 1rem;
  text-align: center;
  position: relative;

  ::before {
    display: ${(p) => (p.hideBackground ? "none" : "block")};
    position: absolute;
    width: 100%;
    height: 100%;
    margin: -1rem;
    content: "";
    border-radius: 1rem;
    filter: blur(10px);
    background: rgba(48, 0, 49, 0.5);
    z-index: -1;
  }

  ul {
    padding: 0;
    margin: 0;
  }
`
