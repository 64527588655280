/* eslint-disable react/jsx-pascal-case */

import { useGLTF } from "@react-three/drei"
import ComponentMaterial from "component-material"
import { BackSide, DoubleSide, MeshStandardMaterial, ShaderChunk } from "three"
import { useRef, useState } from "react"
import { useFrame } from "@react-three/fiber"

import displacebody from "../glsl/displacebody.glsl.js"
import verttop from "../glsl/verttop.glsl.js"
import { usePageDataVector } from "../utils/usePageDataVector"
import { pageData } from "../page-data"
import { state } from "../state"

const matName = "Material.002"

export function Bulb() {
  const { nodes, materials } = useGLTF("gltf/organism-baked.gltf")

  const oldMat = materials[matName]
  const time = useRef(0)

  const { map, metalnessMap, roughnessMap, normalMap } = oldMat

  const material = useRef()

  const amplitude0 = usePageDataVector("bulbAmplitude0", 0.5)
  const amplitude1 = usePageDataVector("bulbAmplitude1", 0.5)
  const frequency0 = usePageDataVector("bulbFrequency0", 0.5)
  const frequency1 = usePageDataVector("bulbFrequency1", 0.5)
  const midlevel = usePageDataVector("bulbMidlevel", 0.5)
  const speed = usePageDataVector("bulbSpeed", 0.5)

  const [showBack, setShowBack] = useState(false)

  useFrame(({ clock }) => {
    time.current += speed.current
    material.current.time = time.current
    material.current.amplitude0 = amplitude0.current
    material.current.amplitude1 = amplitude1.current
    material.current.frequency0 = frequency0.current
    material.current.frequency1 = frequency1.current
    material.current.midlevel = midlevel.current

    setShowBack(pageData.get(state.currPageId).showBack)
  })

  return (
    <mesh geometry={nodes.Icosphere.geometry}>
      <ComponentMaterial
        from={MeshStandardMaterial}
        side={showBack ? BackSide : DoubleSide}
        ref={material}
        map={map}
        metalnessMap={metalnessMap}
        metalness={1}
        roughnessMap={roughnessMap}
        roughness={0.2}
        normalMap={normalMap}
        uniforms={{
          time: { value: 0, type: "float" },
          amplitude0: { value: 0, type: "float" },
          amplitude1: { value: 0, type: "float" },
          frequency0: { value: 0, type: "float" },
          frequency1: { value: 0, type: "float" },
          midlevel: { value: 0, type: "float" },
        }}
      >
        <ComponentMaterial.Vert.Head>{
          /* glsl */ `
${verttop}

vec3 displace(vec3 point) {
  float noise = snoise(vec3(point * frequency0 + time)) * amplitude0;
  noise += snoise(vec3(point * frequency1 * 4. + time)) * amplitude1 * 0.25;
  return point + normal * (noise + midlevel);
}
  `
        }</ComponentMaterial.Vert.Head>
        <ComponentMaterial.Vert.uv_vertex children={displacebody} />
        <ComponentMaterial.Vert.defaultnormal_vertex
          replaceChunk
          children={ShaderChunk.defaultnormal_vertex.replace(
            "vec3 transformedNormal = objectNormal;",
            `vec3 transformedNormal = displacedNormal;`
          )}
        />
        <ComponentMaterial.Vert.displacementmap_vertex
          replaceChunk
          children="transformed = displacedPosition;"
        />
      </ComponentMaterial>
    </mesh>
  )
}
