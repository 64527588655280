import { Canvas } from "@react-three/fiber"
import { Loader, Stats } from "@react-three/drei"
import { Effects } from "./Effects"
import { Scene } from "./Scene"
import { Results } from "./Results"
import { pageData } from "../page-data"
import { useEffect, useRef, useState } from "react"
import { isStatsShowing, startStep, timeoutDurationMinutes } from "../constants"

const isResultsShowing =
  new URLSearchParams(window.location.search).get("results") !== null

export function App() {
  const [step, setStep] = useState(startStep)
  const timer = useRef<NodeJS.Timeout>()

  // Timeout after X minutes and go back to start
  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current)
    }

    if (step !== startStep) {
      timer.current = setTimeout(() => {
        setStep(startStep)
      }, timeoutDurationMinutes * 60 * 1000)
    }
  }, [step])

  return isResultsShowing ? (
    <Results />
  ) : (
    <>
      <Loader />
      {isStatsShowing && <Stats />}

      {Array.from(pageData.entries())
        .filter(([id, item]) => item.isHtml) // Some items are HTML ones, so we cant put them in the scene
        .map(
          ([id, item]) =>
            item.component && (
              <item.component key={id} isVisible={step === id} />
            )
        )}

      <Canvas shadows>
        <Scene setStep={setStep} step={step} />
        <Effects />
      </Canvas>

      {(step === startStep || step === "video") && (
        <a href="https://izdiharafyouni.com/" className="credits">
          More info at izdiharafyouni.com
        </a>
      )}
    </>
  )
}
