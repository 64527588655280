import { startStep } from "../constants"
import { pageData } from "../page-data"

export const getNextStep = (step: string) => {
  const arr = Array.from(pageData)
  const i = arr.findIndex(([key]) => key === step)
  const currItem = arr[i]

  if (currItem[1].nextStep) {
    return currItem[1].nextStep
  } else {
    return arr[i + 1] ? arr[i + 1][0] : startStep
  }
}
