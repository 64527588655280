import "@react-three/fiber"
import { useRef } from "react"
import { BasicBox } from "./BasicBox"
import { saveItem } from "../utils/saveItem"

interface StoredTextProps {
  question: string
  setStep: (step: string) => void
  nextStep: string
}

export function StoredText({ question, setStep, nextStep }: StoredTextProps) {
  const textarea = useRef<HTMLTextAreaElement>(null)

  const handleOnClick = () => {
    if (textarea.current?.value) {
      saveItem(question, textarea.current?.value)
      setStep(nextStep)
      textarea.current.value = ""
    }
  }

  return (
    <BasicBox>
      <h2>{question}</h2>
      <textarea ref={textarea}></textarea>
      <button onClick={handleOnClick}>Verify</button>
    </BasicBox>
  )
}
