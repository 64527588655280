import "@react-three/fiber"
import styled from "styled-components"
import { BasicBox } from "./BasicBox"

const Container = styled.div`
  display: flex;
`

const Col = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:first-child {
    margin-right: 1rem;
  }
`

const List = styled.ul``

const Item = styled.li`
  list-style: none;
  border: 1px solid #fff;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  transition: 0.3s;
  flex: 1;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`

interface MultipleChoiceWithImageProps {
  question: string
  options: string[]
  imageUrl: string
  correctAnswerIndex: number[]
  setStep: (step: string) => void
  nextStep: string
  nextStepBad: string
}

export function MultipleChoiceWithPortraitImage({
  imageUrl,
  question,
  options,
  correctAnswerIndex,
  setStep,
  nextStep,
  nextStepBad,
}: MultipleChoiceWithImageProps) {
  return (
    <BasicBox>
      <Container>
        <Col>
          <img src={imageUrl} alt="" />
        </Col>
        <Col>
          <h3>{question}</h3>
          <List>
            {options.map((option, index) => (
              <Item
                key={index}
                onClick={() => {
                  if (correctAnswerIndex.includes(index)) {
                    setStep(nextStep)
                  } else {
                    setStep(nextStepBad)
                  }
                }}
              >
                {index + 1}. {option}
              </Item>
            ))}
          </List>
        </Col>
      </Container>
    </BasicBox>
  )
}
