import { useFrame } from "@react-three/fiber"
import { Environment } from "@react-three/drei"

import { Creature } from "./Creature"
import { Suspense, useEffect, useRef } from "react"
import { Color, Group, Light, Vector3 } from "three"
import { Camera } from "./Camera"
import { ContentContainer } from "./ContentContainer"
import { SkyBox } from "./SkyBox"
import { getNextStep } from "../utils/getNextStep"

import { pageData } from "../page-data"

import { animatePage } from "../state"
import { usePageDataVector } from "../utils/usePageDataVector"

import { Howl } from "howler"
import { startStep } from "../constants"

interface SceneProps {
  setStep: Function
  step: string
}

const baseSound = new Howl({
  src: ["/sound/base.mp3"],
  loop: true,
  volume: 0.05,
})

const soundShots: Record<string, Howl> = {}

for (let i = 1; i <= 23; i++) {
  soundShots[`angry${i}`] = new Howl({
    src: [`/sound/angry/${i}.mp3`],
    loop: false,
    volume: 1,
  })
  soundShots[`happy${i}`] = new Howl({
    src: [`/sound/happy/${i}.mp3`],
    loop: false,
    volume: 1,
  })
}

document.body.addEventListener("click", () => {
  if (!baseSound.playing()) {
    baseSound.play()
  }
})

export function Scene({ step, setStep }: SceneProps) {
  const lightGroup = useRef<Group>()
  const lightAColor = usePageDataVector("lightAColor", new Color())
  const lightAIntensity = usePageDataVector("lightAIntensity", 1)
  const lightAPosition = usePageDataVector("lightAPosition", new Vector3())

  const lightBColor = usePageDataVector("lightBColor", new Color())
  const lightBIntensity = usePageDataVector("lightBIntensity", 1)
  const lightBPosition = usePageDataVector("lightBPosition", new Vector3())

  const lightA = useRef<Light>()
  const lightB = useRef<Light>()

  const questionTimer = useRef<NodeJS.Timeout>()

  useEffect(() => {
    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        setStep(startStep)
      }
    })
  }, [setStep])

  useFrame(() => {
    if (lightGroup.current) {
      lightGroup.current.rotation.x += 0.03
    }

    if (lightB.current) {
      lightB.current.intensity = lightBIntensity.current
      lightB.current.color.set(lightBColor.current)
      lightB.current.position.copy(lightBPosition.current)
    }

    if (lightA.current) {
      lightA.current.intensity = lightAIntensity.current
      lightA.current.color.set(lightAColor.current)
      lightA.current.position.copy(lightAPosition.current)
    }
  })

  useEffect(() => {
    animatePage(step)

    if (questionTimer.current) {
      clearTimeout(questionTimer.current)
    }

    const currStep = pageData.get(step)!

    const handler = () => {
      setStep(getNextStep(step))
    }

    if (currStep.duration > 0) {
      questionTimer.current = setTimeout(handler, currStep.duration * 1000)
    }

    if (
      (!currStep.component || currStep.clickAnywhereToContinue) &&
      currStep.duration === 0
    ) {
      document.body.addEventListener("click", handler)
    }

    const sound = pageData.get(step)?.sound

    if (sound && soundShots[sound]) {
      soundShots[sound].play()
      soundShots[sound].fade(0, 0.5, 1000)

      if (currStep.soundShouldStay) {
        // @ts-ignore // hack to get sound fading to disable
        soundShots[sound].isFadingDisabled = true
      }
    } else {
      Object.values(soundShots).forEach((sound) => {
        if (sound.playing() && sound.volume() > 0.01) {
          // @ts-ignore // hack to get sound fading to disable
          if (!sound.isFadingDisabled) {
            sound.fade(0.5, 0, 1000)
          }
        }
      })
    }

    return () => {
      document.body.removeEventListener("click", handler)
    }
  }, [step, setStep])

  return (
    <>
      {/* <fogExp2 attach="fog" args={[0x000000, 0.1]} /> */}
      <Camera />
      <Suspense fallback={null}>
        {Array.from(pageData.entries())
          .filter(([id, item]) => !item.isHtml) // Some items are HTML ones, so we cant put them in the scene
          .map(([id, item]) => (
            <ContentContainer
              key={id}
              scale={item.scale}
              position={item.contentPosition}
              isVisible={step === id}
              lookAtPosition={new Vector3()
                .setFromCylindricalCoords(
                  item.camPosition.x,
                  item.camPosition.y,
                  item.camPosition.z
                )
                .add(item.orbitCenter)}
            >
              {item.component && (
                <item.component
                  step={step}
                  setStep={setStep}
                  nextStep={getNextStep(id)}
                  nextStepBad={item.nextStepBad}
                  isVisible={step === id}
                  {...item.componentProps}
                />
              )}
            </ContentContainer>
          ))}

        <Creature />

        <Environment files="images/night-blur.hdr" background={true} />

        <pointLight ref={lightA} />

        <group ref={lightGroup}>
          <pointLight
            ref={lightB}
            position={[-3.5, -3.5, -3.5]}
            color={[0, 1, 0.8]}
            intensity={1}
          />
        </group>

        <SkyBox />
      </Suspense>
    </>
  )
}
