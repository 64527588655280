import "@react-three/fiber"
import styled from "styled-components"
import YouTube from "react-youtube"

import { BasicBox } from "./BasicBox"

interface BoxProps {
  width: number
}

const Box = styled(BasicBox)<BoxProps>`
  width: ${(p) => p.width}px;
  color: white;
`

export function Video(props: any) {
  return (
    <Box
      {...props}
      onClick={() => {
        if (props.nextStep) {
          props.setStep(props.nextStep)
        }
      }}
    >
      {props.isVisible && (
        <YouTube
          videoId="Oeb3OY0xpgk"
          containerClassName="video-container"
          opts={{
            playerVars: {
              // https://developers.google.com/youtube/player_parameters
              autoplay: 1,
              controls: 0,
              showinfo: 0,
              rel: 0,
              modestbranding: 1,
              disablekb: 1,
              loop: 1,
            },
          }}
        />
      )}
    </Box>
  )
}
