import { SaveItem } from "../utils/saveItem"

type Category = {
  question: string
  responses: string[]
}

export function Results() {
  const raw = localStorage.getItem("answers")
  const responses: SaveItem[] = raw ? JSON.parse(raw) : []

  const categories: Record<string, Category> = {}

  responses.forEach((response) => {
    if (categories[response.question]) {
      categories[response.question].responses.push(response.answer)
    } else {
      categories[response.question] = {
        question: response.question,
        responses: [response.answer],
      }
    }
  })

  return (
    <ul style={{ color: "black", whiteSpace: "pre-line" }}>
      {Object.values(categories).map((category) => (
        <div key={category.question}>
          <h3>{category.question}</h3>
          <ul>
            {category.responses.map((response) => (
              <li>{response}</li>
            ))}
          </ul>
        </div>
      ))}
    </ul>
  )
}
