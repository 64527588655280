const forceStart = new URLSearchParams(window.location.search).get("forceStart")

export const breakpoints = {
  medium: 768,
}

export const dimensions = {
  sceneRatio: 1,
  mobSceneRatio: 1,
}

export const isStatsShowing = process.env.REACT_APP_IS_STATS_SHOWING === "true"

export const timeoutDurationMinutes = process.env
  .REACT_APP_TIMEOUT_DURATION_MINUTES
  ? parseFloat(process.env.REACT_APP_TIMEOUT_DURATION_MINUTES)
  : 10

export const startStep = forceStart || "start"
