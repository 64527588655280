import React, { useEffect, useRef } from "react"
import { useFrame } from "@react-three/fiber"
import { PageData, pageData } from "../page-data"
import { state } from "../state"
import { lerp } from "./lerp"

export function usePageDataVector<T>(
  dataId: keyof PageData,
  val: T
): React.MutableRefObject<T> {
  const vec = useRef<any>(val)

  const items = useRef(new Map<string, typeof val>())

  useEffect(() => {
    pageData.forEach((item, pageId) => {
      const itemVec = (item[dataId] as unknown) as T
      items.current.set(pageId, itemVec)
    })
  }, [dataId])

  useFrame(() => {
    const transitionSpeed =
      pageData.get(state.currPageId)?.transitionSpeed || 0.1
    if (typeof vec.current === "number") {
      vec.current = lerp(
        vec.current,
        (items.current.get(state.currPageId) as unknown) as number,
        transitionSpeed
      )
    } else {
      vec.current.lerp(items.current.get(state.currPageId), transitionSpeed)
    }
  })

  return vec
}
